@media screen and (min-width : 320px) {
    .contact-form{

        &__main-container{
            display: flex;
            flex-direction: column;
            margin: auto;
            max-width: 1320px;
            min-height: 90vh;
            padding-inline: 0.4vw;
            width: 100%;
        }
        &__left-container{
            display: none;
            float: left;
            width: 50%;
            img{
                height: 100%;
            }
        }
        &__right-container{
            background-color: rgb(255, 242, 3);
            color: rgb(49, 49, 49);
            display: flex;
            flex-direction: column;
            padding-top: 2vh;
            padding-inline: 2%;
            text-align: center;
            width: 100%;
        }
        &__support-button-container{
            display: flex;
            flex-direction: column;
            margin-top: 6%;
            padding-bottom: 0.2vh;
        }
        &__support-element{
            align-items: center;
            display: flex;
            flex-direction: column; 
            padding-inline: 20px;
            h4{
                font-size: 24px;
                margin-bottom: 0.5em;
            }
            a{
                background-color: rgb(107, 107, 107);
                border-radius: 25px;
                color: #fff;
                display: inline;
                font-size: 20px;
                padding: 10px;
                text-decoration: none;
                text-align: center;
                width: 136.6px;
            }
            p{
                font-size: 20px;
            }
        }
        &__title{
            font-size: 24px;
            margin-bottom: 0;
            margin-top: 15%;
        }
        &__form{
            align-items: center;
            display: flex;
            flex-direction: column;
            float: right;
            justify-content: center;
            padding-bottom: 0.4vh;
            width: 100%;
            & > *{
                display: flex;
                flex-direction: column;
                label{
                    text-align: start;
                }
            }
            input, textarea, button{
                border-radius: 25px;
                font-size: 20px;
                padding: 5px 20px;
            }
            button{
                background-color: rgb(107, 107, 107);   
                color: #fff;
                padding: 10px 20px;
            }
        }
        &__input-container {
            font-size: 20px;
            max-width: 90%;
            padding-block: 25px;
            width: 400px;
        }
    }
}

// @media screen and (min-width : 480px) {}

@media screen and (min-width : 768px) {
    .contact-form{

        &__main-container{
            flex-direction: row;
        }
        &__left-container{
            display: none;
        }
        &__right-container{
            flex-direction: row;
            width: 50%;
        }
        &__support-button-container{
            flex-direction: row;
        }
    }
}

// @media screen and (min-width : 992px) {}
// @media screen and (min-width : 1200px) {}
