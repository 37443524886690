@media screen and (min-width: 320px) {
  .contact-form__main-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1320px;
    min-height: 90vh;
    padding-inline: 0.4vw;
    width: 100%;
  }
  .contact-form__left-container {
    display: none;
    float: left;
    width: 50%;
  }
  .contact-form__left-container img {
    height: 100%;
  }
  .contact-form__right-container {
    background-color: rgb(255, 242, 3);
    color: rgb(49, 49, 49);
    display: flex;
    flex-direction: column;
    padding-top: 2vh;
    padding-inline: 2%;
    text-align: center;
    width: 100%;
  }
  .contact-form__support-button-container {
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    padding-bottom: 0.2vh;
  }
  .contact-form__support-element {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
  }
  .contact-form__support-element h4 {
    font-size: 24px;
    margin-bottom: 0.5em;
  }
  .contact-form__support-element a {
    background-color: rgb(107, 107, 107);
    border-radius: 25px;
    color: #fff;
    display: inline;
    font-size: 20px;
    padding: 10px;
    text-decoration: none;
    text-align: center;
    width: 136.6px;
  }
  .contact-form__support-element p {
    font-size: 20px;
  }
  .contact-form__title {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 15%;
  }
  .contact-form__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    float: right;
    justify-content: center;
    padding-bottom: 0.4vh;
    width: 100%;
  }
  .contact-form__form > * {
    display: flex;
    flex-direction: column;
  }
  .contact-form__form > * label {
    text-align: start;
  }
  .contact-form__form input, .contact-form__form textarea, .contact-form__form button {
    border-radius: 25px;
    font-size: 20px;
    padding: 5px 20px;
  }
  .contact-form__form button {
    background-color: rgb(107, 107, 107);
    color: #fff;
    padding: 10px 20px;
  }
  .contact-form__input-container {
    font-size: 20px;
    max-width: 90%;
    padding-block: 25px;
    width: 400px;
  }
}
@media screen and (min-width: 768px) {
  .contact-form__main-container {
    flex-direction: row;
  }
  .contact-form__left-container {
    display: none;
  }
  .contact-form__right-container {
    flex-direction: row;
    width: 50%;
  }
  .contact-form__support-button-container {
    flex-direction: row;
  }
}/*# sourceMappingURL=contact-section.css.map */