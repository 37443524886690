@media screen and (min-width : 320px) {
    .nav-buttons{
        &__container{
            bottom: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 10vh;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }
    .hero{
        &__container{
            height: 100%;
            min-height: 90vh;
            position: relative;
            width: 100%;
            
            img{
                height: 100%;
                max-height: 90vh;
                max-width: 100%;
                min-height: 90vh;
                min-width: 100%;
                object-fit: cover;
            }
            h1, h2{
                color: #000;
                position: absolute;
                left: 50%;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            h1{
                font-size: 36px;
                top: calc(50% - 65px);
            }
        }
    }
}

// @media screen and (min-width : 480px) {}

@media screen and (min-width : 768px) {}

// @media screen and (min-width : 992px) {}
// @media screen and (min-width : 1200px) {}

