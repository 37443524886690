header{
    background-color: rgb(96, 96, 96);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
    min-height: 10vh;
    position: fixed;
    width: 100%;
    z-index: 100;
    nav{
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 10vh;
        // margin: auto;
        // width: 100%;
        a{
            color: rgb(249, 249, 249);
            cursor: pointer;
            font-size: 18px;
            height: 100%;
            line-height: 10vh;
            padding-inline: 15px;
            text-decoration: none;
            text-align: center;
            width: 120px;
            &:active{
                color: rgb(255, 242, 3);
            }
        }
    }
    .donate{

        &__container{
            display: flex;
            flex-direction: row;
            max-height: 10vh;
            text-decoration: none;
            width: 290px;
            img{
                height: 100%;
            }
            h3{
                color: #fff;
                font-size: 22px;
                // line-height: 10vh;
                margin: 0;
                margin-left: 15px;
                padding-block: 2vh;
            }
        }
    }
}