.nav-links__big-button-link {
  height: 100%;
  width: 33.3%;
  text-decoration: none;
  text-align: center;
  color: rgb(49, 49, 49);
}
.nav-links__big-button-link h4 {
  font-size: 22px;
  margin: 0.7em auto 0.5em;
}
.nav-links__big-button-link:nth-child(even) {
  background-color: rgb(255, 242, 3);
  color: rgb(49, 49, 49);
}
.nav-links__big-button-link:nth-child(odd) {
  background-color: rgb(96, 96, 96);
  color: rgb(49, 49, 49);
}/*# sourceMappingURL=big-nav-button.css.map */