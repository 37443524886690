@media screen and (min-width : 320px) {
    footer{
        background-color: #000;
        background-image: url(../../assets/footer4.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        display: flex;
        flex-direction: column;
        // min-height: 100vh;
        padding: 0 2%;
        .main-container{
            max-width: 1320px;
            margin: auto;
            padding-inline: 0.4vw;
            width: 100%;
        }
        .footer-subscribe{
            padding: 35px;
            .footer-subscribe_header{
                font-size: 36px;
            }
            .footer-subscribe_text{
                font-size: 20px;
                margin: 20px 0 40px;
            }
            .footer-subscribe_link{
                background-color: #0000ff;
                border: none;
                color: #fff;
                font-size: 32px;
                height: 50px;
                margin: 15px;
                max-width: 350px;
                padding: 5px 20px;
                text-decoration: none;
                transition: background-color 1s, color 1s;
                width: 100%;
                &:hover{
                    background-color: #fff;
                    color: #000;
                }
            }
        }
        .footer-container{
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            // padding-top: 80px;
            .footer-image-container{
                height: 100px;
                position: relative;
                text-align: start;
                img{
                    position: absolute;
                    bottom: 0;
                }
            }
            * p{
                margin: 8px;
                text-align: start;
            }
            .footer-sections-container{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                
                div{
                    align-items: start;
                    display: flex;
                    flex-direction: column;
                    margin: 25px;
                    .footer-item_title{
                        text-align: start;
                    }
                    a{
                        color: #fff;;
                        text-decoration: none;
                    }
                }
            }
        }
        .footer-footer{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 15px auto;
            .footer_social-container{
                align-self: center;
                display: flex;
                flex-direction: row;
            }
        }
    }
}

// @media screen and (min-width : 480px) {}

@media screen and (min-width : 768px) {
    footer{
        .footer-container{
            flex-direction: row;
            .footer-image-container{
                height: auto;
            }
        }
        .footer-footer{
            flex-direction: row;
            .footer_social-container{
                align-self: flex-end;
            }
        }
    }
}
// @media screen and (min-width : 992px) {}
// @media screen and (min-width : 1200px) {}
