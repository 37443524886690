.what-we-do__container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1320px;
}
.what-we-do__title, .what-we-do__main-description {
  text-align: center;
}
.what-we-do__title {
  font-size: 36px;
  margin-top: 2em;
}
.what-we-do__main-description {
  font-size: 22px;
}
.what-we-do__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2%;
}
.what-we-do__content > * {
  height: 25vh;
  margin: 0.4vw;
  width: calc(33% - 0.8vw);
}
.what-we-do__content > :nth-child(odd) {
  background-color: yellow;
}
.what-we-do__content img {
  height: 100%;
  width: 100%;
}/*# sourceMappingURL=what-we-do-section.css.map */