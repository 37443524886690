.what-we-do{
    &__container{
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 1320px;
    }
    &__title, &__main-description{
        text-align: center;
    }
    &__title{
        font-size: 36px;
        margin-top: 2em ;
    }
    &__main-description{
        font-size: 22px;
    }
    &__content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 2%;
        & > *{
            height: 25vh;
            margin: 0.4vw;
            width: calc(33% - 0.8vw);
        }
        & > :nth-child(odd){
            background-color: yellow;
        }
        img{
            height: 100%;
            width: 100%;
        }
    }
}