.landing-page__section {
  height: 100%;
  position: relative;
  margin: auto;
  height: 100%;
  min-height: 90vh;
  width: 100%;
}
.landing-page__section:first-child {
  padding: 10vh 0 0vh;
}/*# sourceMappingURL=main-page.css.map */