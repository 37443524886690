.landing-page{

    &__section{
        height: 100%;
        position: relative;
        margin: auto;
        // padding: 10vh 0 10vh;
        height: 100%;
        min-height: 90vh;
        width: 100%;
        &:first-child{
            padding: 10vh 0 0vh;
        }
    }
}