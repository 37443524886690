html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

*{
  box-sizing: border-box;
}

.App{
  position: relative;
}