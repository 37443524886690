.nav-links{

    &__big-button-link{
        height: 100%;
        width: 33.3%;
        text-decoration: none;
        text-align: center;
        color: rgb(49, 49, 49);
        h4{
            font-size: 22px;
            margin: 0.7em auto 0.5em;
        }
        &:nth-child(even){
            background-color: rgb(255, 242, 3);
            color: rgb(49, 49, 49);
            // rgb(168, 159, 20);
        }
        &:nth-child(odd){
            background-color: rgb(96, 96, 96);
            color: rgb(49, 49, 49);
            // 206, 206, 206
        }
    }
}