header {
  background-color: rgb(96, 96, 96);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  min-height: 10vh;
  position: fixed;
  width: 100%;
  z-index: 100;
}
header nav {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 10vh;
}
header nav a {
  color: rgb(249, 249, 249);
  cursor: pointer;
  font-size: 18px;
  height: 100%;
  line-height: 10vh;
  padding-inline: 15px;
  text-decoration: none;
  text-align: center;
  width: 120px;
}
header nav a:active {
  color: rgb(255, 242, 3);
}
header .donate__container {
  display: flex;
  flex-direction: row;
  max-height: 10vh;
  text-decoration: none;
  width: 290px;
}
header .donate__container img {
  height: 100%;
}
header .donate__container h3 {
  color: #fff;
  font-size: 22px;
  margin: 0;
  margin-left: 15px;
  padding-block: 2vh;
}/*# sourceMappingURL=header.css.map */