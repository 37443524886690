@media screen and (min-width: 320px) {
  .nav-buttons__container {
    bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10vh;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .hero__container {
    height: 100%;
    min-height: 90vh;
    position: relative;
    width: 100%;
  }
  .hero__container img {
    height: 100%;
    max-height: 90vh;
    max-width: 100%;
    min-height: 90vh;
    min-width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .hero__container h1, .hero__container h2 {
    color: #000;
    position: absolute;
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .hero__container h1 {
    font-size: 36px;
    top: calc(50% - 65px);
  }
}/*# sourceMappingURL=hero-section.css.map */